import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import Link from 'next/link';
import Slider from 'react-slick';

import * as AuthQueries from '../../views/auth/queries';
import * as DiagnosisReportQueries from '../../views/diagnosisReport/queries';
import * as Queries from './queries';

import MainYellowBottomImg from '../../assets/images/MainYellowBottomImg.png';

const MainProducts = () => {
  const {
    data: {
      auth: { isLoggedIn }
    }
  } = useQuery(AuthQueries.AUTH);

  const { data } = useQuery(DiagnosisReportQueries.ALL_RECORD_RESULT, {
    skip: !isLoggedIn
  });

  const { data: meData } = useQuery(Queries.ME);
  const me = _.get(meData, 'me');
  const { name } = me || {};

  const { data: bestProductsData } = useQuery(Queries.BEST_PRODUCTS, {
    variables: {
      limit: 5
    }
  });
  const { data: allProductData } = useQuery(Queries.ALL_PRODUCT, {
    variables: {
      ordering: '-created',
      limit: 5
    }
  });

  const settings = {
    arrows: true,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 5000,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    slidesToScroll: 4,
    textAlign: 'center',
    pauseOnFocus: true
  };
  return (
    //
    <>
      {isLoggedIn ? (
        <EatmaplProducts>
          <RecommendTitle>
            <span>{name}님께 꼭 맞는 추천상품</span>
            <RecommendBottomImg>
              <img src={MainYellowBottomImg} alt="MainYellowBottomImg" />
            </RecommendBottomImg>
          </RecommendTitle>
          <MobileProducts>
            {bestProductsData?.bestProducts?.data?.map(item => {
              return (
                <Link href={`/eatmarket/${item.code}?productId=${item.id}`} key={item.id}>
                  <EachProduct>
                    <ProductImg>
                      <img src={item.images[0]?.image} alt={item.name} />
                    </ProductImg>
                    <ProductName>{item.name}</ProductName>
                    <ProductPrice>{item.options[0].discountedPrice.toLocaleString()} 원</ProductPrice>
                  </EachProduct>
                </Link>
              );
            })}
          </MobileProducts>
          {/* 웹 프로덕트 */}
          <WebProducts>
            <Slider {...settings}>
              {bestProductsData?.bestProducts?.data?.map(item => {
                return (
                  <Link href={`/eatmarket/${item.code}?productId=${item.id}`} key={item.id}>
                    <WebEachProduct>
                      <WebProductImg>
                        <img src={item.images[0]?.image} alt={item.name} />
                      </WebProductImg>
                      <WebProductName>{item.name}</WebProductName>
                      <WebProductPrice>{item.options[0].discountedPrice.toLocaleString()} 원</WebProductPrice>
                    </WebEachProduct>
                  </Link>
                );
              })}
            </Slider>
          </WebProducts>
        </EatmaplProducts>
      ) : (
        <></>
      )}

      <EatmaplProducts>
        <span>맛있저염 신규 출시 상품</span>
        <MobileProducts>
          {allProductData?.allProduct?.data?.map(item => {
            return (
              <Link href={`/eatmarket/${item.code}?productId=${item.id}`} key={item.id}>
                <EachProduct>
                  <ProductImg>
                    <img src={item.images[0]?.image} alt={item.name} />
                  </ProductImg>
                  <ProductName>{item.name}</ProductName>
                  <ProductPrice>{item.options[0]?.discountedPrice.toLocaleString()} 원</ProductPrice>
                </EachProduct>
              </Link>
            );
          })}
        </MobileProducts>
        <WebProducts>
          <Slider {...settings}>
            {allProductData?.allProduct?.data?.map(item => {
              return (
                <Link href={`/eatmarket/${item.code}?productId=${item.id}`} key={item.id}>
                  <WebEachProduct>
                    <WebProductImg>{<img src={item.images[0]?.image} alt={item.name} />}</WebProductImg>
                    <WebProductName>{item.name}</WebProductName>
                    <WebProductPrice>{item.options[0]?.discountedPrice.toLocaleString()} 원</WebProductPrice>
                  </WebEachProduct>
                </Link>
              );
            })}
          </Slider>
        </WebProducts>
      </EatmaplProducts>
    </>
  );
};

const EatmaplProducts = styled.div`
  text-align: left;
  width: 100%;
  padding-left: 1.6rem;
  margin-bottom: 8.8rem;

  span {
    font-family: TmoneyRoundWindRegular;
    font-weight: 800;
    color: #222;
    font-size: 2.4rem;
    position: relative;
  }
  ${props => props.theme.device.sm} {
    width: 100%;
    margin-bottom: 0rem;

    span {
      font-size: 1.8rem;
    }
  }
`;

const RecommendTitle = styled.div`
  position: relative;
  span {
    z-index: 1;
  }
`;

const RecommendBottomImg = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  img {
    width: 7rem;
    height: 1.1rem;
  }
  ${props => props.theme.device.sm} {
    img {
      width: 5.3rem;
      height: 0.8rem;
    }
  }
`;
const MobileProducts = styled.div`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  display: none;
  cursor: pointer;
  ${props => props.theme.device.sm} {
    display: inline-block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    margin: 2.3rem 0 6.4rem;
  }
`;
const EachProduct = styled.div`
  ${props => props.theme.device.sm} {
    display: inline-block;
    margin-right: 1.5rem;
  }
`;

const ProductImg = styled.div`
  ${props => props.theme.device.sm} {
    width: 13.6rem;
    height: 13.6rem;
    img {
      /* object-fit: contain; */
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
`;
const ProductName = styled.div`
  ${props => props.theme.device.sm} {
    width: 12.9rem;
    height: 4rem;
    font-family: Spoqa Han Sans;
    font-size: 1.6rem;
    margin-top: 1.2rem;
    color: #444;
    line-height: 1.25;
    white-space: normal;
    display: block;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ProductPrice = styled.div`
  ${props => props.theme.device.sm} {
    font-family: Spoqa Han Sans;
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-top: 0.8rem;
  }
`;

const WebProducts = styled.div`
  ${props => props.theme.device.sm} {
    display: none;
  }
`;

const WebEachProduct = styled.div`
  width: 26rem;
  margin-top: 3.4rem;
  cursor: pointer;
`;

const WebProductImg = styled.div`
  width: 26rem;
  height: 26rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;

const WebProductName = styled.div`
  height: 5.4rem;
  line-height: 1.25;
  text-align: left;
  margin-top: 2rem;
  font-family: Spoqa Han Sans;
  font-size: 2rem;
  text-align: left;
  color: #444;
  padding-right: 4rem;

  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const WebProductPrice = styled.div`
  text-align: left;
  margin-top: 1.6rem;
  font-size: 2.2rem;
  font-weight: bold;
  color: #222;
`;

export default MainProducts;
