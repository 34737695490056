/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';

import Layout from '../components/Layout';
import PopUp from '../components/PopUp';

import MainBanner from '../components/Main/MainBanner';
import MainEatmaplProcess from '../components/Main/MainEatmaplProcess';
import MainProducts from '../components/Main/MainProducts';
import MainReview from '../components/Main/MainReview';
import MainTips from '../components/Main/MainTips';
import MainMenuDevelopLog from '../components/Main/MainMenuDevelopLog';
import MainPartner from '../components/Main/MainPartner';
import MainIntroduceAndOrder from '../components/Main/MainIntroduceAndOrder';

import { GRAPHQL_URI } from '../lib/apollo/initApollo';
import { MainRealClickTrackingScript } from '../lib/addRealClickScript';

const photoReviewsQuery = ` query PhotoReviews {
                    photoReviews {
                        id
                        created
                        updated
                        contents
                        stars
                        photoUrl1
                        photoUrl2
                        photoUrl3
                        tags
                        tasteState
                        wrappingState
                        orderline {
                            id
                            name
                            productVariant {
                            product {
                                id
                                }
                            }
                        }
                        order {
                            isSubscriptionOrder
                            applicantName
                            name
                            product {
                                id
                            }
                        }
                        adminComment
                        }
                }`;

const allPartnerDataQuery = `query AllPartner {
    allPartner {
      partnerName
      partnerAlt
      partnerLogoM
      partnerUrl
      isActive
    }
  }`;

export async function getStaticProps() {
  const photoReviewsDataJson = await fetch(GRAPHQL_URI, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: photoReviewsQuery,
      variables: {}
    })
  }).then(res => res.json());

  const allPartnerDataJson = await fetch(GRAPHQL_URI, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: allPartnerDataQuery,
      variables: {}
    })
  }).then(res => res.json());
  return {
    props: {
      photoReviewsDataJson,
      allPartnerDataJson
    }
  };
}

const Main = ({ photoReviewsDataJson, allPartnerDataJson }) => {
  useEffect(() => {
    const script = MainRealClickTrackingScript();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!photoReviewsDataJson) {
    return (
      <Layout store>
        <Skeleton active />
      </Layout>
    );
  }

  return (
    <Layout isSticky paddingTop={false}>
      <PopUp />
      <Container>
        <MainBanner />
        <MainEatmaplProcess />
        <MainProducts />
        <MainReview photoReviewsDataJson={photoReviewsDataJson} />
        <MainTips />
        <MainMenuDevelopLog />
        <MainIntroduceAndOrder />
        <MainPartner allPartnerDataJson={allPartnerDataJson} />
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  ${props => props.theme.device.sm} {
    width: 100%;
  }
`;

export default Main;
