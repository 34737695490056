import React from 'react';
import styled from 'styled-components';
import WebFirstProcess from '../../assets/images/WebFirstProcess.png';
import MobileFistProcess from '../../assets/images/MobileFistProcess.png';
import WebSecondProcess from '../../assets/images/WebSecondProcess.png';
import MobileSecondProcess from '../../assets/images/MobileSecondProcess.png';
import WebThirdProcess from '../../assets/images/WebThirdProcess.png';
import MobileThirdProcess from '../../assets/images/MobileThirdProcess.png';
import MobileFourthProcess from '../../assets/images/MobileFourthProcess.png';

const MainEatmaplProcess = () => {
  return (
    // 정보형 컨텐츠 유지
    <Container>
      <FirstProcess>
        <ProcessContainer>
          <NumberBox>
            <Number>
              <span>1.</span>
            </Number>
          </NumberBox>
          <WebProcessImg>
            <img src={WebFirstProcess} alt="WebFirstProcess" />
          </WebProcessImg>
          <MobileProcessImg>
            <img src={MobileFistProcess} alt="MobileFirstProcess" />
          </MobileProcessImg>
          <ProcessContents>
            <ProcessTitle>건강 상태 파악</ProcessTitle>
            <ProcessContent>의학 임상영양학 기준 소비자의 정확한 건강 상태를 파악</ProcessContent>
          </ProcessContents>
        </ProcessContainer>
      </FirstProcess>
      <SecondProcess>
        <ProcessContainer>
          <NumberBox>
            <Number>
              <span>2.</span>
            </Number>
          </NumberBox>
          <WebProcessImg>
            <img src={WebSecondProcess} alt="WebFirstProcess" />
          </WebProcessImg>

          <MobileProcessImg>
            <img src={MobileSecondProcess} alt="MobileSecondProcess" />
          </MobileProcessImg>
          <ProcessContents>
            <ProcessTitle>1:1 케어식단 매칭</ProcessTitle>
            <ProcessContent>
              환자의 현재 건강상태를 파악하여 정확한 수치 기반 식단을 매칭
            </ProcessContent>
          </ProcessContents>
        </ProcessContainer>
      </SecondProcess>
      <ThirdProcess>
        <ProcessContainer>
          <NumberBox>
            <Number>
              <span>3.</span>
            </Number>
          </NumberBox>
          <WebProcessImg>
            <img src={WebThirdProcess} alt="WebFirstProcess" />
          </WebProcessImg>

          <MobileProcessImg>
            <img src={MobileThirdProcess} alt="MobileThirdProcess" />
          </MobileProcessImg>
          <ProcessContents>
            <ProcessTitle>식단 정기배송</ProcessTitle>
            <ProcessContent>호텔 셰프가 직접 조리한 식단을 정기적으로 배송 진행</ProcessContent>
          </ProcessContents>
        </ProcessContainer>
      </ThirdProcess>
      <FourthProcess>
        <ProcessContainer>
          <NumberBox>
            <Number>
              <span>4.</span>
            </Number>
          </NumberBox>
          <WebProcessImg>
            <img src={MobileFourthProcess} alt="WebFirstProcess" />
          </WebProcessImg>
          <MobileProcessImg>
            <img src={MobileFourthProcess} alt="MobileThirdProcess" />
          </MobileProcessImg>
          <ProcessContents>
            <ProcessTitle>1:1영양코칭 서비스</ProcessTitle>
            <ProcessContent>환자의 영양정보를 바탕으로 영양사와 1:1영양컨설팅</ProcessContent>
          </ProcessContents>
        </ProcessContainer>
      </FourthProcess>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 1.6rem;
  margin-bottom: 8.8rem;
  margin-right: 0.8rem;
  width: 100%;
  height: 13.6rem;
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  position: relative;
  ${props => props.theme.device.sm} {
    padding: 0 0;
    margin-bottom: 0;
    margin-top: 1.6rem;
    width: 100%;
    height: 9.2rem;
    margin-bottom: 4.8rem;
  }
`;
const FirstProcess = styled.div`
  width: 35.2rem;
  height: 13.6rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cecece;
  margin-right: 1.6rem;
  display: inline-block;

  ${props => props.theme.device.sm} {
    width: 34.4rem;
    height: 9.2rem;
    margin: 0 0.8rem;
  }
`;
const SecondProcess = styled.div`
  width: 35.2rem;
  height: 13.6rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cecece;
  margin-right: 1.6rem;
  display: inline-block;

  ${props => props.theme.device.sm} {
    width: 34.4rem;
    height: 9.2rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cecece;
    border-radius: 4px;
    margin-right: 0;
  }
`;

const ThirdProcess = styled.div`
  width: 35.2rem;
  height: 13.6rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cecece;
  margin-right: 1.6rem;
  display: inline-block;

  ${props => props.theme.device.sm} {
    width: 34.4rem;
    height: 9.2rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cecece;
    border-radius: 4px;
    margin: 0 0.8rem;
  }
`;

const FourthProcess = styled.div`
  width: 35.2rem;
  height: 13.6rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cecece;
  display: inline-block;

  ${props => props.theme.device.sm} {
    width: 34.4rem;
    height: 9.2rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cecece;
    border-radius: 4px;
    margin-right: 0.8rem;
  }
`;

const ProcessContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.6rem 2.4rem 0.6rem 0.6rem;
  ${props => props.theme.device.sm} {
  }
`;

const NumberBox = styled.div`
  width: 2.8rem;
  height: 12.4rem;
  /* opacity: 0.7; */
  background-color: #ff7e1f;
  padding-left: 0.5rem;
  z-index: 2;
  opacity: 0.7;
  border-radius: 4px;

  ${props => props.theme.device.sm} {
    width: 2.4rem;
    height: 8rem;
    opacity: 1;
    position: relative;
    text-align: center;
    padding: 0.5rem 0.4rem;
  }
`;
const Number = styled.div`
  position: absolute;
  font-family: TmoneyRoundWindRegular;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  ${props => props.theme.device.sm} {
  }
`;

const WebProcessImg = styled.div`
  display: inline;
  width: 12.4rem;
  height: 12.4rem;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
  }
  ${props => props.theme.device.sm} {
    display: none;
  }
`;

const MobileProcessImg = styled.div`
  display: none;
  /* width: 12.4rem;
  height: 12.4rem; */
  /* background-color: wheat; */
  ${props => props.theme.device.sm} {
    display: inline;
    width: 8rem;
    height: 8rem;
    margin-left: 0.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const ProcessContents = styled.div`
  margin-left: 1.6rem;
  position: absolute;
  margin-left: 14.6rem;
  ${props => props.theme.device.sm} {
    margin-left: 1.4rem;
    position: relative;
    /* margin-left: 0; */
  }
`;

const ProcessTitle = styled.div`
  font-family: TmoneyRoundWindRegular;
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.5;
  color: #ff7e1f;
  text-align: left;

  ${props => props.theme.device.sm} {
    font-size: 14px;
    margin-bottom: 0.6rem;
  }
`;

const ProcessContent = styled.div`
  width: 18.3rem;
  height: 7.3rem;
  font-family: Spoqa Han Sans;
  font-size: 1.7rem;
  margin-top: 0.8rem;
  white-space: normal;
  br {
    display: none;
  }
  line-height: 1.41;
  ${props => props.theme.device.sm} {
    width: 19rem;
    height: 4rem;
    font-size: 1.4rem;
    line-height: 1.43;
    color: #444;
    margin-top: 0;
  }
`;
export default MainEatmaplProcess;
