import Tip1 from '../../assets/tips/1/1-1.svg';
import Tip2 from '../../assets/tips/2/2-2.svg';
import Tip3 from '../../assets/tips/3/3-1.svg';
import Tip4 from '../../assets/tips/4/4-1.svg';
import Tip5 from '../../assets/tips/5/5-1.svg';
import Tip6 from '../../assets/tips/6/6-1.svg';
import Tip7 from '../../assets/tips/7/7-1.svg';
import Tip8 from '../../assets/tips/8/8-1.svg';
import Tip9 from '../../assets/tips/9/9-1.svg';
import Tip10 from '../../assets/tips/10/10_thum.svg';
import Tip11 from '../../assets/tips/11/11-1.svg';
import Tip12 from '../../assets/tips/12/12-1.svg';
import Tip13 from '../../assets/tips/13/13-1.svg';
import Tip14 from '../../assets/tips/14/14-1.svg';
import Tip15 from '../../assets/tips/15/15-1.svg';
import Tip16 from '../../assets/tips/16/16-1.svg';
import Tip17 from '../../assets/tips/17/17-1.svg';
import Tip18 from '../../assets/tips/18/18-1.svg';
import Tip19 from '../../assets/tips/19/19-1.svg';
import Tip20 from '../../assets/tips/20/20-1.svg';
import Tip21 from '../../assets/tips/21/21-1.svg';
import Tip22 from '../../assets/tips/22/22-1.svg';
import Tip23 from '../../assets/tips/23/23-1.svg';
import Tip24 from '../../assets/tips/24/24-1.svg';
import Tip25 from '../../assets/tips/25/25-1.svg';
import Tip26 from '../../assets/tips/26/26-1.jpg';
import Tip27 from '../../assets/tips/27/27-1.jpg';
import Tip28 from '../../assets/tips/28/28-1.jpg';
import Tip29 from '../../assets/tips/29/29-1.jpg';
import Tip30 from '../../assets/tips/30/30-1.jpg';
import Tip31 from '../../assets/tips/31/31-1.jpg';
import Tip32 from '../../assets/tips/32/32-1.jpg';
import Tip33 from '../../assets/tips/33/33-1.jpg';
import Tip34 from '../../assets/tips/34/34-1.jpg';
import Tip35 from '../../assets/tips/35/35-1.jpg';
import Tip36 from '../../assets/tips/36/36-1.jpg';
import Tip37 from '../../assets/tips/37/37-1.jpg';
import Tip38 from '../../assets/tips/38/38-1.jpg';
import Tip39 from '../../assets/tips/39/39-1.jpg';
import Tip40 from '../../assets/tips/40/40-1.jpg';

// eslint-disable-next-line import/prefer-default-export
export const talktalkData = [
  {
    id: 1,
    img: Tip1,
    type: 'a',
    url: '/tips/1',
    desc: '당뇨병이 있는 만성콩팥병 환자를 위한 식사요법',
    alt: '식사꿀팁1',
    date: '2022.03.04'
  },
  {
    id: 2,
    img: Tip2,
    type: 'a',
    url: '/tips/2',
    desc: '저염조미료(저염소금, 저염간장)는 만성콩팥병 환자에게는 오히려 위험',
    alt: '식사꿀팁2',
    date: '2022.03.11'
  },
  {
    id: 3,
    img: Tip3,
    type: 'b',
    url: '/tips/3',
    desc: '당뇨환자가 밀가루음식도 건강하고 맛있게 먹는 방법',
    alt: '식사꿀팁3',
    date: '2022.03.18'
  },
  { id: 4, img: Tip4, type: 'c', url: '/tips/4', desc: '비만환자를 위한 식사요법과 식품선택팁', alt: '식사꿀팁4', date: '2022.03.25' },
  { id: 5, img: Tip5, type: 'c', url: '/tips/5', desc: '통풍환자를 위한 식사요법, 저퓨린식', alt: '식사꿀팁5', date: '2022.04.01' },
  { id: 6, img: Tip6, type: 'c', url: '/tips/6', desc: '일상생활에서 당류 섭취 줄이는 법', alt: '식사꿀팁6', date: '2022.04.08' },
  { id: 7, img: Tip7, type: 'c', url: '/tips/7', desc: '염분은 낮추고 맛은 올리는 조리법', alt: '식사꿀팁7', date: '2022.04.15' },
  { id: 8, img: Tip8, type: 'c', url: '/tips/8', desc: '영양정보표시 제대로 읽는 법과 식품선택기준', alt: '식사꿀팁8', date: '2022.04.22' },
  { id: 9, img: Tip9, type: 'b', url: '/tips/9', desc: '당뇨병환자를 위한 과일 섭취 방법 ', alt: '식사꿀팁9', date: '2022.04.29' },
  { id: 10, img: Tip10, type: 'b', url: '/tips/10', desc: '당뇨환자를 위한 외식 메뉴 선택 가이드', alt: '식사꿀팁10', date: '2022.05.06' },
  { id: 11, img: Tip11, type: 'c', url: '/tips/11', desc: '다양한 양념류의 나트륨 함량 알기', alt: '식사꿀팁11', date: '2022.05.13' },
  { id: 12, img: Tip12, type: 'c', url: '/tips/12', desc: '방사선 요오드 치료 시 식품 선택 가이드', alt: '식사꿀팁12', date: '2022.06.10' },
  { id: 13, img: Tip13, type: 'c', url: '/tips/13', desc: '균형 식사와 건강한 당분 선택에  운동 더하기', alt: '식사꿀팁13', date: '2022.06.16' },
  { id: 14, img: Tip14, type: 'a', url: '/tips/14', desc: '칼륨 조절 위한 식품 선택법', alt: '식사꿀팁14', date: '2022.06.23' },
  { id: 15, img: Tip15, type: 'a', url: '/tips/15', desc: '인 조절 위한 식품 선택법', alt: '식사꿀팁15', date: '2022.06.30' },
  { id: 16, img: Tip16, type: 'a', url: '/tips/16', desc: '단백질 섭취 방법', alt: '식사꿀팁16', date: '2022.07.07' },
  { id: 17, img: Tip17, type: 'c', url: '/tips/17', desc: '식사와 운동으로 예방 가능한 고혈압 ', alt: '식사꿀팁17', date: '2022.07.14' },
  { id: 18, img: Tip18, type: 'c', url: '/tips/18', desc: '고혈압 환자를 위한 균형식의 중요성', alt: '식사꿀팁18', date: '2022.07.21' },
  { id: 19, img: Tip19, type: 'b', url: '/tips/19', desc: '혈당 관리를 위한 올바른 밥 섭취법 ', alt: '식사꿀팁19', date: '2022.07.28' },
  { id: 20, img: Tip20, type: 'a', url: '/tips/20', desc: '만성콩팥병 환자를 위한 간식 선택법', alt: '식사꿀팁20', date: '2022.08.04' },
  { id: 21, img: Tip21, type: 'b', url: '/tips/21', desc: '혈당 관리를  위한 올바른 단백질 섭취법 ', alt: '식사꿀팁21', date: '2022.08.11' },
  { id: 22, img: Tip22, type: 'a', url: '/tips/22', desc: '만성콩팥병 환자를 위한 빈혈 영양 관리법', alt: '식사꿀팁22', date: '2022.08.18' },
  { id: 23, img: Tip23, type: 'c', url: '/tips/23', desc: '요산에 관한 모든 것 ', alt: '식사꿀팁23', date: '2022.08.25' },
  { id: 24, img: Tip24, type: 'b', url: '/tips/24', desc: '혈당 관리를 위한 추석 음식 섭취법 ', alt: '식사꿀팁24', date: '2022.09.01' },
  { id: 25, img: Tip25, type: 'a', url: '/tips/25', desc: '콩팥 건강을 위한 추석 음식 섭취법 ', alt: '식사꿀팁25', date: '2022.09.08' },
  { id: 26, img: Tip26, type: 'ㄱ', url: '/tips/26', desc: '우리 몸에서 콩팥이 하는 일', alt: '식사꿀팁26', date: '2022.10.13' },
  { id: 27, img: Tip27, type: 'ㄴ', url: '/tips/27', desc: '우리 몸에서 나트륨이 하는 일', alt: '식사꿀팁27', date: '2022.10.20' },
  { id: 28, img: Tip28, type: 'ㄷ', url: '/tips/28', desc: '고구마의 영양정보와 건강한 섭취법', alt: '식사꿀팁28', date: '2022.10.27' },
  { id: 29, img: Tip29, type: 'ㄱ', url: '/tips/29', desc: '콩팥병이 의심될 때 이렇게 체크해보세요', alt: '식사꿀팁29', date: '2022.11.07' },
  { id: 30, img: Tip30, type: 'ㄴ', url: '/tips/30', desc: '나트륨 과잉 섭취 우리 몸에 어떤가요?', alt: '식사꿀팁30', date: '2022.11.10' },
  { id: 31, img: Tip31, type: 'ㄷ', url: '/tips/31', desc: '수온이 내려가는 때가 제철! 영양소  가득! 고등어', alt: '식사꿀팁31', date: '2022.11.17' },
  { id: 32, img: Tip32, type: 'ㄱ', url: '/tips/32', desc: '콩팥병! 종류도 여러가지라고요?', alt: '식사꿀팁32', date: '2022.12.01' },
  { id: 33, img: Tip33, type: 'ㄴ', url: '/tips/33', desc: '저염식, 어떻게 시작하면 좋을까요?', alt: '식사꿀팁33', date: '2022.12.08' },
  { id: 34, img: Tip34, type: 'ㄷ', url: '/tips/34', desc: '달달한 겨울 무, 알고보면 영양 만점?!', alt: '식사꿀팁34', date: '2022.12.22' },
  { id: 35, img: Tip35, type: 'ㄷ', url: '/tips/35', desc: '맛있는 줄만 알았지? 영양 가득 품은 귤', alt: '식사꿀팁35', date: '2023.01.05' },
  { id: 36, img: Tip36, type: 'ㄴ', url: '/tips/36', desc: '삶의 질을 높이는 방법! 맞춤식단, 들어보셨나요?', alt: '식사꿀팁36', date: '2023.01.19' },
  { id: 37, img: Tip37, type: 'ㄴ', url: '/tips/37', desc: '당뇨질환자를 위한 맞춤식단,안심하고 먹어도 될까요?', alt: '식사꿀팁37', date: '2023.04.10' },
  { id: 38, img: Tip38, type: 'ㄴ', url: '/tips/38', desc: '암환자를 위한 맞춤식단,안심하고 먹어도 될까요?', alt: '식사꿀팁38', date: '2023.04.19' },
  { id: 39, img: Tip39, type: 'ㄴ', url: '/tips/39', desc: '암환자 건강 회복을 위한 바른 식사방법 -첫번째편-', alt: '식사꿀팁39', date: '2024.02.01' },
  { id: 40, img: Tip40, type: 'ㄴ', url: '/tips/40', desc: '암환자 건강 회복을 위한 바른 식사방법 -두번째편-', alt: '식사꿀팁40', date: '2024.02.01' }
];
