import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import MainBlueBottomImg from '../../assets/images/MainBlueBottomImg.png';

const MainPartner = ({ allPartnerDataJson }) => {
  //   const { data: allPartnerData } = useQuery(Queries.ALL_PARTNER);
  const allPartnerData = _.get(allPartnerDataJson, 'data');
  return (
    //
    <>
      <PartnerTitle>
        <span>맛있저염 Partner</span>
        <PartnerBottomImg>
          <img src={MainBlueBottomImg} alt="MainBlueBottomImg" />
        </PartnerBottomImg>
      </PartnerTitle>
      <PartnersContainer>
        <Partners>
          {allPartnerData?.allPartner?.map((item, index) => {
            return (
              <Partner key={index}>
                <PartnerLogo>
                  <img src={item.partnerLogoM} alt={item.partnerAlt} />
                </PartnerLogo>
                <PartnerName>
                  <span>{item.partnerName}</span>
                </PartnerName>
              </Partner>
            );
          })}
        </Partners>
      </PartnersContainer>
      <IntroducePartners>*맛있저염의 건강식이요법 개발을 위한 협업기업 소개</IntroducePartners>
    </>
  );
};

const PartnerTitle = styled.div`
  position: relative;
  font-family: TmoneyRoundWindRegular;
  font-size: 2.4rem;
  font-weight: 800;
  text-align: center;
  color: #222;
  margin-bottom: 2.2rem;
  position: relative;
  span {
    position: relative;
    z-index: 1;
  }
  ${props => props.theme.device.sm} {
    font-size: 1.8rem;
  }
`;
const PartnerBottomImg = styled.div`
  object-fit: contain;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 2.4rem;
  img {
    width: 19.2rem;
    height: 1.2rem;
  }
  ${props => props.theme.device.sm} {
    height: 1.8rem;
    img {
      width: 14.4rem;
      height: 0.88rem;
    }
  }
`;

const PartnersContainer = styled.div`
  width: 100%;
  height: 23.6rem;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* padding: 4rem 0; */
  /* padding-top: 4rem; */
  ::-webkit-scrollbar {
    display: none;
  }
  ${props => props.theme.device.sm} {
    height: 14.2rem;
    padding: 0 1.6rem;
  }
`;
const Partners = styled.div`
  width: 100rem;
  min-width: 112rem;
  margin-right: 1.6rem;
  height: 100%;
  border-radius: 4px;
  border: solid 1px #cecece;
  padding: 2.88rem 0 1.6rem;
  display: flex;
`;

const Partner = styled.div`
  text-align: center;
  border-right: 1px #cecece solid;
  width: 17.6rem;
  height: 100%;
  :last-child {
    border-right: none;
  }
  ${props => props.theme.device.sm} {
    width: 14rem;
  }
`;
const PartnerLogo = styled.div`
  img {
    width: 10rem;
    height: 10rem;
    object-fit: contain;
  }
  ${props => props.theme.device.sm} {
    img {
      width: 5rem;
      height: 5rem;
    }
  }
`;
const PartnerName = styled.div`
  border-radius: 4px;
  border: solid 1px #cecece;
  padding: 1px 6px 3px;
  display: inline-block;
  position: relative;
  top: 4rem;
  /* top: 16.4rem; */
  span {
    font-family: Spoqa Han Sans;
    font-size: 1.8rem;
    color: #444;
  }
  ${props => props.theme.device.sm} {
    top: 2rem;
    span {
      font-size: 1.4rem;
    }
  }
`;
const IntroducePartners = styled.div`
  font-family: Spoqa Han Sans;
  text-align: right;
  color: #b2b2b2;
  font-size: 1.8rem;
  margin-top: 1.6rem;
  margin-bottom: 8rem;
  margin-right: 1.6rem;
  ${props => props.theme.device.sm} {
    margin-right: 2rem;

    font-size: 1.4rem;

    margin: 1.6rem 2rem 4.8rem;
  }
`;

export default MainPartner;
