import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import * as Queries from '../../components/Main/queries';

import styled from 'styled-components';
import { Modal, Icon } from 'antd';
import { setCookie } from 'nookies';
import { relativeTimeRounding } from 'moment';

const popUp = () => {
  const { data: allPopUpData } = useQuery(Queries.ALL_POP_UP);

  const allPopUp = _.get(allPopUpData, 'allPopUp') || [];
  const [nodePopupIndex, setNodePopupIndex] = useState(0);
  const nodePopUp = allPopUp[nodePopupIndex];
  const { image, url } = nodePopUp || {};
  const resetBodyStyle = () => {
    document.body.style.overflow = '';
  };
  const handleNextpopUp = () => {
    setNodePopupIndex(nodePopupIndex + 1);
  };

  function getPopCookie(name) {
    const cookie = document.cookie;
    if (document.cookie != '') {
      const cookieArray = cookie.split('; ');
      for (let index in cookieArray) {
        let cookie_name = cookieArray[index].split('=');
        if (cookie_name[0] == name) {
          setNodePopupIndex(nodePopupIndex + 1);
        }
      }
    }
  }

  if (nodePopUp !== undefined) {
    const PopNum = nodePopUp.id;
    getPopCookie(`Eatmarpl${PopNum}`);
  }

  const setPopCookie = (name, value, expiredays) => {
    let todayDate = new Date();
    // todayDate.setDate(todayDate.getDate() + expiredays);
    todayDate = new Date(parseInt(todayDate.getTime() / 86400000) * 86400000 + 54000000);
    if (todayDate > new Date()) {
      expiredays = expiredays - 1;
    }
    document.cookie = `${name}${nodePopUp.id}= ${escape(value)}; path=/ ; expires=${todayDate.toGMTString()};`;
  };

  const ClosePop = () => {
    setPopCookie('Eatmarpl', 'y', 1);
    handleNextpopUp();
  };

  // 테스트 삭제 부분
  // function deleteCookie(name) {
  //   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  // }

  // deleteCookie('Eatmarpl14');

  return (
    <Modal afterClose={resetBodyStyle} visible={Boolean(nodePopUp)} footer={null} onCancel={handleNextpopUp} style={{ top: '2rem' }} bodyStyle={{ padding: '0' }}>
      {nodePopUp ? (
        <>
          <a href={url} target="_blank">
            <ImageContainer>
              <img style={{ maxWidth: '100%', maxHeight: '100%', height: '100%', width: 'inherit' }} src={image} alt="공지사항 이미지" />
            </ImageContainer>
          </a>
          <CloseButton>
            <NotSeeingDay onClick={ClosePop}>오늘 하루 안보기</NotSeeingDay>
            <NotSeeing onClick={handleNextpopUp}>닫기</NotSeeing>
          </CloseButton>
        </>
      ) : null}
    </Modal>
  );
};
const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const CloseButton = styled.div`
  display: flex;
  height: 4.8rem;
  line-height: 4.8rem;
  background-color: white;
  font-family: Spoqa Han Sans;
  text-align: center;
  font-size: 1.6rem;
  color: black;
  cursor: pointer;
`;
const NotSeeingDay = styled.div`
  width: 50%;
  border-right: 0.1px solid lightgray;
`;
const NotSeeing = styled.div`
  width: 50%;
`;

export default popUp;
