// 리얼클릭 리타겟팅 스크립트 _app

export const injectRetargetTrackingScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;

  const inlineScriptContent = `
        function dsp_loadrtgJS(b, c) {
            const headElement = document.getElementsByTagName("head")[0];
            const scriptElement = document.createElement("script");
            scriptElement.type = "text/javascript";
            
            if (c) {
                scriptElement.charset = "euc-kr";
            }
            
            scriptElement.src = b;
            scriptElement.async = true;
            headElement.appendChild(scriptElement);
        }

        function dsp_load_rtg(b) {
            const protocol = document.location.protocol === "https:" ? "https://" : "http://";
            dsp_loadrtgJS(protocol + b, "euc-kr");
        }

        dsp_load_rtg("realdmp.realclick.co.kr/rtarget/rtget.js?dsp_adid=microsalts");
    `;

  script.textContent = inlineScriptContent;
  document.body.appendChild(script);
};

// 리얼클릭 방문 , 구매 스크립트 공통
export function injectRealClickTrackingScript(dspu = 'CU1bWlyb3NsYXRz', dspt = '', dspo = '', dspom = '') {
  // Create the new script element
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;

  // Define the inline script content using template literals
  const inlineScriptContent = `
        var dspu = "${dspu}";
        var dspt = "${dspt}";
        var dspo = "${dspo || ''}";
        var dspom = "${dspom || ''}";

        function loadanalJS_dsp(b, c) {
            var d = document.getElementsByTagName("head")[0],
                a = document.createElement("script");
            a.type = "text/javascript";
            if (c) a.charset = "UTF-8";
            a.src = b;
            a.async = true;
            d.appendChild(a);
        }

        function loadanal_dsp(b) {
            loadanalJS_dsp((document.location.protocol === "https:" ? "https://" : "http://") + b, "UTF-8");

            var hiddenSpan = document.createElement("span");
            hiddenSpan.id = "dsp_spn";
            hiddenSpan.style.display = "none";
            document.body.appendChild(hiddenSpan);
        }

        loadanal_dsp("tk.realclick.co.kr/tk_comm.js?dspu=" + dspu + "&dspt=" + dspt + "&dspo=" + dspo + "&dspom=" + dspom);
    `;

  // Add the content to the script element and then append to the body
  newScript.textContent = inlineScriptContent;
  document.body.appendChild(newScript);
}

// DSP_쇼핑타겟팅_일반_메인 스크립트
export function MainRealClickTrackingScript() {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;

  newScript.textContent = `
      var real_cic = 'dsp373018,dsp373019';

      function loadanalJS_sp(b, c) {
        var d = document.getElementsByTagName('head')[0],
            a = document.createElement('script');
        a.type = 'text/javascript';
        null != c && (a.charset = 'UTF-8');
        a.src = b;
        a.async = true;
        d.appendChild(a);
      }

      function loadanal_sp(b) {
        loadanalJS_sp((document.location.protocol === 'https:' ? 'https://' : 'http://') + b, 'UTF-8');
      }
      
      var real_core = (function() {
        return {
          onload: function(p) {
            var u =
              p +
              eval('String.fromCharCode(47,47,101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,115,101,116,116,105,110,103,46,112,104,112)') +
              eval('String.fromCharCode(63,99,109,61)') +
              'MAIN' +
              eval('String.fromCharCode(38,99,105,99,61)') +
              real_cic;
            var c = document.createElement(eval('String.fromCharCode(105,102,114,97,109,101)'));
            var hiddenSpan = document.createElement("span");
            hiddenSpan.id = "real_spn";
            hiddenSpan.style.display = "none";
            document.body.appendChild(hiddenSpan);
            if (c) {
              c.width = '1px';
              c.height = '1px';
              c.style.display = 'none';
              c.src = u;
              var d = document.getElementById('real_spn');
              if (d != null && d.appendChild) {
                d.appendChild(c);
              }
            }
            loadanal_sp(eval('String.fromCharCode(101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,116,107,47,116,107,95,99,111,109,109,46,106,115)') + '?rmu=' + real_cic);
          }
        };
      })();
      real_core.onload(document.location.protocol);
    `;

  return newScript;
}

// DSP_쇼핑타겟팅_일반_결제완료 스크립트
export function PaymentCompleteRealClickTrackingScript({ realItem = '', realPrice = '', realPaycode = '', realTotalPrice = '' }) {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;

  newScript.textContent = `
        var real_item = '${realItem}'; 
        var real_price = '${realPrice}'; 
        var real_paycode = '${realPaycode}'; 

        var rmom = '${realTotalPrice}'; // === (선택)구매금액( 구매전환시 사용 ) ===

        var rmt = '1'; 
        var rmo = real_paycode; // === (선택)주문번호( 미입력시 - 중복체크 안함. ) ===
        var real_cic = 'dsp373018,dsp373019';

        function loadanalJS_sp(b, c) {
        var d = document.getElementsByTagName('head')[0],
            a = document.createElement('sc' + 'ript');
        a.type = 'text/javasc' + 'ript';
        null != c && (a.charset = 'UTF-8');
        a.src = b;
        a.async = 'true';
        d.appendChild(a);
        }
        function loadanal_sp(b) {
        loadanalJS_sp(('https:' == document.location.protocol ? 'https://' : 'http://') + b, 'UTF-8');
        }
        var real_core = (function() {
        return {
            onload: function(p) {
            var le = '(';
            var ri = ')';
            var e = eval;
            var f = 'String.fromCharCode';
            var u =
                p +
                e(f + le + '47,47,101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,115,101,116,116,105,110,103,46,112,104,112' + ri) +
                e(f + le + '63,99,109,61' + ri) +
                'PAY' +
                e(f + le + '38,105,116,101,109,61' + ri) +
                real_item +
                e(f + le + '38,99,105,99,61' + ri) +
                real_cic +
                e(f + le + '38,112,97,121,99,111,100,101,61' + ri) +
                real_paycode +
                e(f + le + '38,103,112,114,105,99,101,61' + ri) +
                real_price;
            var c = document.createElement(e(f + le + '105,102,114,97,109,101' + ri));
            var hiddenSpan = document.createElement('span');
            hiddenSpan.id = 'real_spn';
            hiddenSpan.style.display = 'none';
            document.body.appendChild(hiddenSpan);
            if (c) {
                c.width = '1px';
                c.height = '1px';
                c.style.display = 'none';
                c.src = u;
                var d = document.getElementById('real_spn');
                if (d != null && d.appendChild) {
                d.appendChild(c);
                }
            }
            loadanal_sp(e(f + le + '101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,116,107,47,116,107,95,99,111,109,109,46,106,115' + ri) + '?rmu=' + real_cic);
            }
        };
        })();
        real_core.onload(document.location.protocol);

      `;

  return newScript;
}

// DSP_쇼핑타겟팅_일반_리스트메인 스크립트
export function ListMainRealClickTrackingScript() {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;

  newScript.textContent = `
        var real_cic = 'dsp373018,dsp373019';

        function loadanalJS_sp(b, c) {
        var d = document.getElementsByTagName('head')[0],
            a = document.createElement('script');
        a.type = 'text/javascript';
        null != c && (a.charset = 'UTF-8');
        a.src = b;
        a.async = 'true';
        d.appendChild(a);
        }
        function loadanal_sp(b) {
        loadanalJS_sp(('https:' == document.location.protocol ? 'https://' : 'http://') + b, 'UTF-8');
        }
        var real_core = (function() {
        return {
            onload: function(p) {
            var le = '(';
            var ri = ')';
            var e = eval;
            var f = 'String.fromCharCode';
            var u =
                p +
                e(f + le + '47,47,101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,115,101,116,116,105,110,103,46,112,104,112' + ri) +
                e(f + le + '63,99,109,61' + ri) +
                'MAIN' +
                e(f + le + '38,99,105,99,61' + ri) +
                real_cic;
            var c = document.createElement(e(f + le + '105,102,114,97,109,101' + ri));
            var hiddenSpan = document.createElement('span');
            hiddenSpan.id = 'real_spn';
            hiddenSpan.style.display = 'none';
            document.body.appendChild(hiddenSpan);
            if (c) {
                c.width = '1px';
                c.height = '1px';
                c.style.display = 'none';
                c.src = u;
                var d = document.getElementById('real_spn');
                if (d != null && d.appendChild) {
                d.appendChild(c);
                }
            }
            loadanal_sp(e(f + le + '101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,116,107,47,116,107,95,99,111,109,109,46,106,115' + ri) + '?rmu=' + real_cic);
            }
        };
        })();
        real_core.onload(document.location.protocol);
      `;
  return newScript;
}

// DSP_쇼핑타겟팅_일반_상세 스크립트
export function DetailRealClickTrackingScript({ realItem = '' }) {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;

  newScript.textContent = `
          var real_item = '${realItem}'; 
          var real_cic = 'dsp373018,dsp373019';
  
          function loadanalJS_sp(b, c) {
            var d = document.getElementsByTagName('head')[0],
              a = document.createElement('script');
            a.type = 'text/javascript';
            null != c && (a.charset = 'UTF-8');
            a.src = b;
            a.async = 'true';
            d.appendChild(a);
          }
          function loadanal_sp(b) {
            loadanalJS_sp(('https:' == document.location.protocol ? 'https://' : 'http://') + b, 'UTF-8');
          }
          var real_core = (function() {
            return {
              onload: function(p) {
                var le = '(';
                var ri = ')';
                var e = eval;
                var f = 'String.fromCharCode';
                var u =
                  p +
                  e(f + le + '47,47,101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,115,101,116,116,105,110,103,46,112,104,112' + ri) +
                  e(f + le + '63,99,109,61' + ri) +
                  'DTL' +
                  e(f + le + '38,105,116,101,109,61' + ri) +
                  real_item +
                  e(f + le + '38,99,105,99,61' + ri) +
                  real_cic;
                var c = document.createElement(e(f + le + '105,102,114,97,109,101' + ri));
                var hiddenSpan = document.createElement('span');
                hiddenSpan.id = 'real_spn';
                hiddenSpan.style.display = 'none';
                document.body.appendChild(hiddenSpan);
                if (c) {
                  c.width = '1px';
                  c.height = '1px';
                  c.style.display = 'none';
                  c.src = u;
                  var d = document.getElementById('real_spn');
                  if (d != null && d.appendChild) {
                    d.appendChild(c);
                  }
                }
                loadanal_sp(e(f + le + '101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,116,107,47,116,107,95,99,111,109,109,46,106,115' + ri) + '?rmu=' + real_cic);
              }
            };
          })();
          real_core.onload(document.location.protocol);
        `;

  return newScript;
}

// DSP_쇼핑타겟팅_일반_장바구니 스크립트
export function CartRealClickTrackingScript({ realItem = '' }) {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;

  newScript.textContent = `
            var real_item = '${realItem}'; 
            var real_cic = 'dsp373018,dsp373019';
    
            function loadanalJS_sp(b, c) {
                var d = document.getElementsByTagName('head')[0],
                    a = document.createElement('script');
                a.type = 'text/javascript';
                null != c && (a.charset = 'UTF-8');
                a.src = b;
                a.async = 'true';
                d.appendChild(a);
            }
            function loadanal_sp(b) {
                loadanalJS_sp(('https:' == document.location.protocol ? 'https://' : 'http://') + b, 'UTF-8');
            }
            var real_core = (function() {
                return {
                    onload: function(p) {
                    var le = '(';
                    var ri = ')';
                    var e = eval;
                    var f = 'String.fromCharCode';
                    var u =
                        p +
                        e(f + le + '47,47,101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,115,101,116,116,105,110,103,46,112,104,112' + ri) +
                        e(f + le + '63,99,109,61' + ri) +
                        'BSK' +
                        e(f + le + '38,105,116,101,109,61' + ri) +
                        real_item +
                        e(f + le + '38,99,105,99,61' + ri) +
                        real_cic;
                    var c = document.createElement(e(f + le + '105,102,114,97,109,101' + ri));
                    var hiddenSpan = document.createElement('span');
                    hiddenSpan.id = 'real_spn';
                    hiddenSpan.style.display = 'none';
                    document.body.appendChild(hiddenSpan);
                    if (c) {
                        c.width = '1px';
                        c.height = '1px';
                        c.style.display = 'none';
                        c.src = u;
                        var d = document.getElementById('real_spn');
                        if (d != null && d.appendChild) {
                        d.appendChild(c);
                        }
                    }
                    loadanal_sp(e(f + le + '101,118,101,110,116,46,114,101,97,108,99,108,105,99,107,46,99,111,46,107,114,47,115,112,47,116,107,47,116,107,95,99,111,109,109,46,106,115' + ri) + '?rmu=' + real_cic);
                    }
                };
            })();
            real_core.onload(document.location.protocol);
          `;

  return newScript;
}
