import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import LinkButton from '../../assets/images/LinkButton.png';

import MainBlueBottomImg from '../../assets/images/MainBlueBottomImg.png';
import MainYellowBottomImg from '../../assets/images/MainYellowBottomImg.png';
import LinkButtonBlack from '../../assets/images/LinkButtonBlack.png';

import WebFirstIntroduceImg from '../../assets/images/WebFirstIntroduce.png';
import MobileSecondIntroduceImg from '../../assets/images/SecondIntroduceImg.png';
import WebSecondIntroduceImg from '../../assets/images/WebSecondIntroduce.png';
import MobileFirstIntroduceImg from '../../assets/images/FirstIntroduceImg.png';
import WebThirdIntroduceImg from '../../assets/images/WebThirdIntroduce.png';
import WebDignosisImg from '../../assets/images/ManToManDiagnosis.png';
import DiagnosisOrderImg from '../../assets/images/DiagnosisOrder.png';
import MainpageOpacity from '../../assets/images/MainpageOpacity.png';
import MobileThirdIntroduceImg from '../../assets/images/ThirdIntroduceImg.png';

const MainIntroduceAndOrder = () => {
  return (
    //
    <>
      <IntroduceCarousel>
        <CustomizedDiet>
          <CustomizedDietTitle>
            <span>1:1맞춤식단이란?</span>
          </CustomizedDietTitle>
          <CustomizedDietBottomImg>
            <img src={MainBlueBottomImg} alt="MainBlueBottomImg" />
          </CustomizedDietBottomImg>
          <CustomizedDietSubtitle>
            <span>
              고객별 진단 맞춤식단 <br /> 구독 서비스
            </span>
          </CustomizedDietSubtitle>
          <Link href="/diagnosis">
            <CustomizedDietLink>
              <span>자세히 보기</span>
              <img src={LinkButton} alt="LinkButton" />
            </CustomizedDietLink>
          </Link>
        </CustomizedDiet>

        <WhatIsEatmarket>
          <WhatIsEatmarketTitle>
            <span>맛저몰이란?</span>
          </WhatIsEatmarketTitle>
          <WhatIsEatmarketBottomImg>
            <img src={MainBlueBottomImg} alt="MainBlueBottomImg" />
          </WhatIsEatmarketBottomImg>
          <WhatIsEatmarketSubtitle>
            <span>
              건강데이터 기반 <br /> 건강먹거리 마켓
            </span>
          </WhatIsEatmarketSubtitle>
          <Link href="/eatmarket">
            <WhatIsEatmarketLink>
              <span>자세히 보기</span>
              <img src={LinkButton} alt="LinkButton" />
            </WhatIsEatmarketLink>
          </Link>
        </WhatIsEatmarket>
        <IntroduceEatmapl>
          <IntroduceEatmaplTitle>
            <span>잇마플 소개</span>
          </IntroduceEatmaplTitle>
          <IntroduceEatmaplBottomImg>
            <img src={MainYellowBottomImg} alt="MainYellowBottomImg" />
          </IntroduceEatmaplBottomImg>
          <IntroduceEatmaplSubtitle>잇마플 스토리</IntroduceEatmaplSubtitle>
          <Link href="/about">
            <IntroduceEatmaplLink>
              <span>자세히 보기</span>
              <img src={LinkButtonBlack} alt="LinkButtonBlack" />
            </IntroduceEatmaplLink>
          </Link>
        </IntroduceEatmapl>
      </IntroduceCarousel>
      <ManToManDiagnosis>
        <OpacityImg>
          <Link href="/diagnosis">
            <LinkDiagnosis>
              <DiagnosisOrder>1:1맞춤식단 주문하러 가기</DiagnosisOrder>
            </LinkDiagnosis>
          </Link>
        </OpacityImg>
      </ManToManDiagnosis>
    </>
  );
};

const IntroduceCarousel = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0 1.6rem;
  ${props => props.theme.device.sm} {
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
    margin-top: 6rem;
    padding: 0;
  }
`;
const WhatIsEatmarket = styled.div`
  display: inline-block;
  border-radius: 4px;
  border: solid 1px #cecece;
  width: 35.2rem;
  height: 27.2rem;
  margin-right: 1.6rem;
  background-image: url(${WebSecondIntroduceImg});
  background-size: contain;
  ${props => props.theme.device.sm} {
    margin: 0 1.2 0rem;
    background-image: url(${MobileFirstIntroduceImg});
    width: 31.1rem;
    height: 24rem;
    /* margin-right: 0; */
    padding: 2.3rem 15.2rem 2.1rem 2.1rem;
  }
`;
const WhatIsEatmarketTitle = styled.div`
  position: absolute;
  font-family: TmoneyRoundWindRegular;
  font-weight: 800;
  color: #222;
  font-size: 2rem;
  margin-top: 2.8rem;
  margin-left: 2.4rem;
  span {
    position: relative;
    z-index: 1;
  }
  ${props => props.theme.device.sm} {
    font-size: 1.8rem;
    margin-left: 0;
    margin-top: 0;
  }
`;
const WhatIsEatmarketBottomImg = styled.div`
  position: absolute;
  width: 10.8rem;
  height: 0.8rem;
  margin-top: 4.5rem;
  margin-left: 2.4rem;
  img {
    width: 100%;
    height: 100%;
  }
  ${props => props.theme.device.sm} {
    width: 9.6rem;
    height: 0.8rem;
    margin-top: 1.5rem;
    margin-left: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
const WhatIsEatmarketSubtitle = styled.div`
  position: absolute;
  margin-top: 6.8rem;
  font-family: Spoqa Han Sans;
  font-weight: bold;
  color: #444;
  margin-left: 2.4rem;
  font-size: 1.8rem;
  font-weight: bold;
  ${props => props.theme.device.sm} {
    margin-top: 3.3rem;
    margin-left: 0;
    font-size: 1.4rem;
  }
`;
const WhatIsEatmarketLink = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20rem;
  margin-left: 2.4rem;
  color: #b2b2b2;
  span {
    font-family: Spoqa Han Sans;
    font-size: 1.8rem;
    margin-right: 1.2rem;
  }
  img {
    width: 0.6rem;
    height: 1.2rem;
  }
  ${props => props.theme.device.sm} {
    margin-top: 18rem;
    line-height: 2.1rem;
    margin-left: 0;
    span {
      font-family: Spoqa Han Sans;
      font-size: 1.4rem;
      font-weight: bold;
      color: #b2b2b2;
      margin-right: 1.1rem;
    }
    img {
      height: 1.2rem;
      width: 0.6rem;
      object-fit: contain;
    }
  }
`;
const CustomizedDiet = styled.div`
  display: inline-block;
  border-radius: 4px;
  border: solid 1px #cecece;
  width: 35.2rem;
  height: 27.2rem;
  margin-right: 1.6rem;
  background-image: url(${WebFirstIntroduceImg});
  background-size: contain;
  ${props => props.theme.device.sm} {
    width: 31.1rem;
    height: 24rem;
    margin-right: 0;
    margin: 0 1.2rem;
    padding: 9.9rem 12.3rem 2.1rem 2.1rem;
    background-image: url(${MobileSecondIntroduceImg});
    background-size: contain;
  }
`;
const CustomizedDietTitle = styled.div`
  position: absolute;
  font-family: TmoneyRoundWindRegular;
  font-weight: 800;
  color: #fff;
  font-size: 2rem;
  margin-left: 2.4rem;
  margin-top: 10.2rem;
  span {
    position: relative;
    z-index: 1;
  }
  ${props => props.theme.device.sm} {
    font-size: 1.8rem;
    margin-top: 0;
    margin-left: 0;
  }
`;
const CustomizedDietBottomImg = styled.div`
  position: absolute;
  width: 16.4rem;
  height: 0.8rem;
  margin-left: 2.4rem;
  margin-top: 11.8rem;
  img {
    width: 100%;
    height: 100%;
  }
  ${props => props.theme.device.sm} {
    width: 14.6rem;
    height: 0.8rem;
    margin-left: 0;
    margin-top: 1.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
const CustomizedDietSubtitle = styled.div`
  position: absolute;
  font-family: Spoqa Han Sans;
  font-weight: bold;
  font-size: 1.8rem;
  color: #f2f2f2;
  margin-left: 2.4rem;
  margin-top: 13.7rem;
  ${props => props.theme.device.sm} {
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
    margin-top: 3.5rem;
    margin-left: 0;
    span {
    }
  }
`;
const CustomizedDietLink = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 21rem;
  margin-left: 2.4rem;
  font-size: 1.8rem;
  color: #f2f2f2;
  span {
    margin-right: 1.2rem;
  }
  img {
    width: 0.6rem;
    height: 1.2rem;
  }
  ${props => props.theme.device.sm} {
    margin-top: 18rem;
    line-height: 2.1rem;
    margin-top: 10.6rem;
    margin-left: 0;
    span {
      font-family: Spoqa Han Sans;
      font-size: 1.4rem;
      font-weight: bold;
      color: #b2b2b2;
      margin-right: 1.1rem;
    }
    img {
      height: 1.2rem;
      width: 0.6rem;
      object-fit: contain;
    }
  }
`;
const IntroduceEatmapl = styled.div`
  display: inline-block;
  border-radius: 4px;
  border: solid 1px #cecece;
  background-image: url(${WebThirdIntroduceImg});
  background-size: contain;
  width: 35.2rem;
  height: 27.2rem;
  ${props => props.theme.device.sm} {
    white-space: word-break;
    width: 31.1rem;
    height: 24rem;
    background-image: url(${MobileThirdIntroduceImg});
    background-size: contain;
    padding: 2.9rem 2.3rem 2.1rem 16.7rem;
  }
`;
const IntroduceEatmaplTitle = styled.div`
  position: absolute;
  font-family: TmoneyRoundWindRegular;
  font-weight: 800;
  color: #222;
  font-size: 2rem;
  margin-left: 17.9rem;
  margin-top: 2.9rem;
  span {
    position: relative;
    z-index: 1;
  }
  ${props => props.theme.device.sm} {
    font-size: 1.8rem;
    margin-left: 0;
    margin-top: 0;
  }
`;
const IntroduceEatmaplBottomImg = styled.div`
  position: absolute;
  width: 10.5rem;
  height: 0.8rem;
  margin-left: 17.9rem;
  margin-top: 4.5rem;
  img {
    width: 100%;
    height: 100%;
  }
  ${props => props.theme.device.sm} {
    width: 9.6rem;
    height: 0.8rem;
    margin-left: 0;
    margin-top: 1.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
const IntroduceEatmaplSubtitle = styled.div`
  position: absolute;
  font-family: Spoqa Han Sans;
  font-weight: bold;
  color: #444;
  font-size: 1.8rem;
  margin-top: 6.8rem;
  margin-left: 17.9rem;
  ${props => props.theme.device.sm} {
    width: 12rem;
    white-space: normal;
    margin-top: 3.3rem;
    margin-left: 0;
    font-size: 1.4rem;
    span {
      width: 100%;
    }
  }
`;
const IntroduceEatmaplLink = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: right;
  margin-left: 22.7rem;
  margin-top: 20rem;
  span {
    font-size: 1.8rem;
    margin-right: 1.2rem;
    font-family: Spoqa Han Sans;
    font-weight: bold;
    color: #444;
  }
  img {
    width: 0.6rem;
    height: 1.2rem;
  }
  ${props => props.theme.device.sm} {
    margin-top: 18rem;
    line-height: 2.1rem;
    margin-top: 17.1rem;
    margin-left: 0;
    span {
      font-size: 1.4rem;
      margin-right: 1.1rem;
    }
    img {
      height: 1.2rem;
      width: 0.6rem;
      object-fit: contain;
    }
  }
`;
const ManToManDiagnosis = styled.div`
  position: relative;
  height: 40rem;
  background-image: url(${WebDignosisImg});
  top: -5rem;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  ${props => props.theme.device.sm} {
    z-index: 1;
    background-image: url(${DiagnosisOrderImg});
    height: 25rem;
  }
`;
const OpacityImg = styled.div`
  background-image: url(${MainpageOpacity});
  width: 100%;
  height: 40rem;
  ${props => props.theme.device.sm} {
    height: 25rem;
  }
`;
const LinkDiagnosis = styled.div`
  width: 43.8rem;
  height: 8rem;
  border-radius: 4px;
  border: solid 1px #f27000;
  background-color: rgba(255, 126, 31, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  ${props => props.theme.device.sm} {
    width: 26rem;
    height: 4.8rem;
    padding: 1.1rem 3rem 1.2rem;
  }
`;

const DiagnosisOrder = styled.div`
  font-family: Spoqa Han Sans;
  text-align: center;
  color: #fff;
  font-size: 2.4rem;
  line-height: 8rem;
  ${props => props.theme.device.sm} {
    width: 100%;
    font-size: 1.7rem;
    line-height: 2.71rem;
  }
`;

export default MainIntroduceAndOrder;
